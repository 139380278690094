import React, { useEffect, useMemo, useRef, useState } from 'react';
import { BackButton } from '../../components/AtomComponents';
import useDataService from '../../hooks/useDataService';
import './InternalUserManagement.scss';
import SearchOnButtonClick from '../../components/SearchOnButtonClick/SearchOnButtonClick';
import {
  getColumnDefs,
  gridOptions,
  IUserInfo,
} from '../../components/InternalUserManagement/gridUtils';
import { AgGridReact } from 'ag-grid-react';
import {
  GridApi,
  GridReadyEvent,
  RowDataChangedEvent,
  RowDataUpdatedEvent,
  RowSelectedEvent,
} from 'ag-grid-community';
import CustomGridHeaderCheckbox from './CustomGridHeaderCheckbox';
import apiToUrlMap from '../../ApiMapping';
import { isEmpty } from '../../_lib/utils';
import LoadingDialog from '../../components/LoadingDialog/LoadingDialog';

function InternalUserManagement() {
  const [emailList, setEmailList] = useState<IUserInfo[]>([]);
  const [gridAPI, setGridAPI] = useState<GridApi>();
  const [loading, setLoading] = useState<boolean>(false);
  const { fetchUrl, openSnackBar, pollAndFetch } = useDataService();
  const columnDefs = useMemo(() => getColumnDefs(), []);
  const headerCheckboxRef = useRef<any>();

  // useEffect(() => {
  //   if (!gridAPI) return;
  //   gridAPI.setRowData([]);
  //   gridAPI.hideOverlay();
  // }, [gridAPI]);

  useEffect(() => {
    if (!gridAPI) return;
    gridAPI.setRowData(emailList);
    gridAPI.hideOverlay();
    setTimeout(() => {
      console.log(emailList);
      if (!emailList.length) gridAPI.showNoRowsOverlay();
    }, 0);
  }, [emailList, gridAPI]);

  async function fetchUserEmails(searchTerm: string) {
    if (!gridAPI) return;
    if (!searchTerm) {
      setEmailList([]);
      // gridAPI.setRowData([]);
      return;
    }
    try {
      gridAPI.showLoadingOverlay();
      const res = await fetchUrl('POST', apiToUrlMap.getInternalUsers, {
        body: { email: searchTerm },
      });
      setEmailList(res);
    } catch (error: any) {
      openSnackBar(error.message, 'error');
    }
  }

  async function onGridReady(params: GridReadyEvent) {
    if (params.api) {
      setGridAPI(params.api);
    }
  }

  function onHeaderCheckboxSelectionChange(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.checked) gridAPI?.selectAll();
    else gridAPI?.deselectAll();
  }

  function onRowDataChanged(event: RowDataChangedEvent | RowDataUpdatedEvent) {
    gridAPI?.deselectAll();
    headerCheckboxRef.current?.onRowDataChanged(event);
  }

  function onSelectionChanged(event: RowSelectedEvent) {
    headerCheckboxRef.current?.onSelectionChanged(event);
  }

  async function offBoardUsers() {
    setLoading(true);
    const selectedUsers = gridAPI?.getSelectedRows();
    if (isEmpty(selectedUsers)) return;
    try {
      await pollAndFetch({
        httpMethod: 'POST',
        path: apiToUrlMap.offboardInternalUsers,
        additionalParams: {
          body: selectedUsers,
        },
      });
      openSnackBar(`Selected Users are offboarded successfully`, 'success');
      gridAPI?.deselectAll();
    } catch (error: any) {
      console.error(error);
      openSnackBar(error.message, 'error');
    }
    setLoading(false);
  }

  return (
    <div className="px-internal-user-management">
      <div className="cell small-6 px-one-line-data">
        <LoadingDialog isDialogOpen={loading} />
        <BackButton />
        <h3>Internal User Management</h3>
      </div>
      <div className="grid-x margin-top-1 margin-left-1">
        <form className="grid-x cell small-12 margin-top-1 margin-bottom-1">
          <SearchOnButtonClick
            onSearch={fetchUserEmails}
            className="cell small-6"
            placeholder="Search Email Address"
          />
        </form>
        <CustomGridHeaderCheckbox
          itemsSummaryLabel={`{count} Users Selected`}
          buttonProps={[
            {
              label: 'Offboard Users',
              onClick: offBoardUsers,
            },
          ]}
          onChange={onHeaderCheckboxSelectionChange}
          ref={headerCheckboxRef}
        />
        <div
          className="cell small-12 ag-theme-alpine px-height-100vh margin-top-2"
          style={{ width: '1000px' }}>
          <AgGridReact
            gridOptions={gridOptions}
            columnDefs={columnDefs}
            onRowDataChanged={onRowDataChanged}
            onSelectionChanged={onSelectionChanged}
            onGridReady={onGridReady}
          />
        </div>
      </div>
    </div>
  );
}

export default InternalUserManagement;
