import { useErrorStatus } from '../ErrorHandler';
import React, { useEffect, useRef } from 'react';
import { API, Auth } from 'aws-amplify';
import { IMessageType, eMessageType } from '../types/IMessageType';

import { downloadFile, previewPdf, sanitize } from '../_lib/lib';
import { Redirect } from 'react-router-dom';

const useDataService = () => {
  const {
    openSnackBar,
  }: {
    openSnackBar(message: any, type: IMessageType): any;
  } = useErrorStatus();

  const pxConfig =
    localStorage.getItem('PxConfig') ||
    `{"tenantApiName":"tenantSpecificEndpoint", "tenantApiEndPoint":"https://m63qk19h95.execute-api.us-east-1.amazonaws.com/Integration"}`;
  const apiName = JSON.parse(pxConfig).tenantApiName;
  const tenantEndPoint = JSON.parse(pxConfig).tenantApiEndPoint;

  // fetchUrl(getCustomerParams(buyerId, buyerData)) {}

  const fetchUrl = async (
    httpMethod: string,
    path: string,
    additionalParams: any = {},
    manualCatchHandle: any = null
  ) => {
    let result: any;
    // console.log(path, 'path')
    try {
      switch (httpMethod.toUpperCase()) {
        case 'GET': {
          result = await API.get(apiName, path, additionalParams);
          break;
        }
        case 'POST': {
          result = await API.post(apiName, path, additionalParams);
          break;
        }
        case 'PUT': {
          result = await API.put(apiName, path, additionalParams);
          break;
        }
        case 'PATCH': {
          result = await API.patch(apiName, path, additionalParams);
          break;
        }
        case 'DELETE': {
          result = await API.del(apiName, path, additionalParams);
          break;
        }
        default:
          return {
            default: true,
          };
      }
      // successful response rules
      // for put we need a null or if the responseType is text then true
      // for post we need a null or some json
      // get must have some body
      // delete must be a null response
      return result;
    } catch (error: any) {
      return handleError(error, manualCatchHandle);
    }
  };

  const isRendering = useRef<boolean>();

  useEffect(() => {
    isRendering.current = true;
    return () => {
      isRendering.current = false;
    };
  }, []);

  const delay = (interval: number) => new Promise((resolve) => setTimeout(resolve, interval));

  const pollAndFetch = async ({
    httpMethod,
    path,
    additionalParams = {},
    manualCatchHandle,
    pollParams = { interval: 3000, maxSeconds: 59000 },
    executedIntervals = 0,
  }: {
    httpMethod: string;
    path: string;
    pollParams?: { interval: number; maxSeconds: number };
    additionalParams?: any;
    manualCatchHandle?: any;
    executedIntervals?: number;
  }): Promise<{ isRendering: boolean; response: any }> => {
    //stop the polling if we navigate to any other page or max seconds reached
    if (!isRendering.current) return { isRendering: false, response: null };
    //throw error only when request timed out
    if (executedIntervals > pollParams.maxSeconds) throw new Error('Request Timed out');

    try {
      const res = await fetchUrl(httpMethod, path, additionalParams, manualCatchHandle);
      //handling normal fetch response - fallback
      // if (!res.requestId) return res;

      //status is completed
      if (res.status === 'COMPLETED') {
        return { isRendering: isRendering.current, response: res.response };
      } else {
        //append request id in subsequent calls
        const headers = additionalParams.headers ?? {};
        headers.request_id = res.requestId;

        await delay(pollParams.interval); // Wait for the interval

        return await pollAndFetch({
          httpMethod,
          path,
          additionalParams: { ...additionalParams, headers },
          manualCatchHandle,
          pollParams,
          executedIntervals: executedIntervals + pollParams.interval,
        });
      }
    } catch (error: any) {
      return handleError(error, false);
    }
  };

  interface IFileUploadHeader {
    header: string;
    value: string;
  }

  const fileUpload = async (
    file: File,
    path: string,
    headers?: Array<IFileUploadHeader>,
    contentType?: string
  ) => {
    return new Promise(async (resolve: any, reject: any) => {
      try {
        var xmlHttpRequest = new XMLHttpRequest();
        xmlHttpRequest.open('POST', tenantEndPoint + path, true);
        xmlHttpRequest.setRequestHeader(
          'Content-Type',
          contentType
            ? contentType
            : 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        );
        xmlHttpRequest.setRequestHeader(
          'Authorization',
          `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
        );
        xmlHttpRequest.setRequestHeader('file-name', sanitize(file.name));
        if (headers) {
          headers.forEach((val) => {
            xmlHttpRequest.setRequestHeader(val.header, val.value);
          });
        }
        xmlHttpRequest.setRequestHeader('Accept', 'application/json');
        xmlHttpRequest.onreadystatechange = function () {
          // In local files, status is 0 upon success in Mozilla Firefox
          if (xmlHttpRequest.readyState === XMLHttpRequest.DONE) {
            if (xmlHttpRequest.status !== 200) {
              console.log(xmlHttpRequest.response);
              reject(
                JSON.parse(
                  xmlHttpRequest.response.length > 0
                    ? xmlHttpRequest.response
                    : '{"message":"Something went wrong"}'
                )
              );
              return;
            }
            resolve(
              JSON.parse(xmlHttpRequest.response.length > 0 ? xmlHttpRequest.response : '{}')
            );
          }
        };
        xmlHttpRequest.send(file);
      } catch (error: any) {
        handleError(error, false);
      }
    });
  };

  interface IExportData {
    url: string;
    method?: string;
    fileName?: string;
    body?: any;
    headers?: any;
    isPreviewOnly?: boolean;
  }

  const exportData = async ({ url, method, fileName, body,headers, isPreviewOnly }: IExportData) => {
    try {
      const file = await fetchUrl(method || 'get', url, {
        body: body || {},
        headers: {
          accept: 'application/excel',
          ...headers,
        },
        responseType: 'blob',
        response: true,
      });
      if (isPreviewOnly) return await previewPdf(file, fileName);
      return await downloadFile(file, fileName);
    } catch (error: any) {
      handleError(error, false);
    }
  };

  const unAuthorizedFetchUrl = (httpMethod: string, path: string, requestBody: any = {}) => {
    return new Promise(async (resolve: any, reject: any) => {
      try {
        const response = await fetch(tenantEndPoint + path, {
          method: httpMethod,
          body: JSON.stringify(requestBody),
        });

        if (response.status !== 200) {
          const errorResponse = await response.json();
          reject(errorResponse);
          return;
        }

        const data = await response.json();
        resolve(data);
      } catch (error: any) {
        handleError(error, false);
      }
    });
  };

  const handleError = (error: any, manualCatchHandle: boolean) => {
    const { response } = error;
    let errorMessage = response?.data?.message || error?.message || 'Internal Server Error';
    console.log(response, 'response', response?.status);

    if (!response || response?.status === 401 || response?.data?.statusCode === 401) {
      // redirect
       <Redirect to="/" />;
    }
    if (response?.data?.statusCode === 500) {
      errorMessage = 'Invalid Request';
    }
    if (!manualCatchHandle) {
      openSnackBar(errorMessage, eMessageType.error);
    }
    throw new Error(errorMessage);
  };

  return { fetchUrl, openSnackBar, fileUpload, exportData, unAuthorizedFetchUrl, pollAndFetch };
};

export default useDataService;
