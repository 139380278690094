import React, { useState, useImperativeHandle } from 'react';
import { ButtonProps, CheckboxProps } from '@material-ui/core';
import {
  RowDataChangedEvent,
  RowDataUpdatedEvent,
  RowSelectedEvent,
} from '@ag-grid-community/core';
import { PrimaryButton, PrimaryCheckbox } from '../../components/AtomComponents';
import { formatString } from '../../ApiMapping';

export interface IButtonProps extends ButtonProps {
  startAdornment?: React.ReactNode;
  label: string;
  'data-id'?: string;
}

interface ICustomGridHeaderCheckbox {
  className?: string;
  checkboxProps?: CheckboxProps;
  buttonProps?: IButtonProps[];
  itemsSummaryLabel?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

function CustomGridHeaderCheckbox(
  { className, checkboxProps, buttonProps, itemsSummaryLabel, onChange }: ICustomGridHeaderCheckbox,
  ref: any
) {
  const [headerCheckboxState, setHeaderCheckboxState] = useState<{
    isChecked: boolean;
    isIndeterminate: boolean;
    count: number;
    selectableRowLength: number;
  }>({
    isChecked: false,
    isIndeterminate: false,
    count: 0,
    selectableRowLength: 0,
  });

  function onRowDataChanged(event: RowDataChangedEvent | RowDataUpdatedEvent) {
    let selectableRowLength = 0;
    event.api.forEachNode((node) => {
      if (node.group) return;
      selectableRowLength += node.selectable ? 1 : 0;
    });
    setHeaderCheckboxState({
      isChecked: false,
      isIndeterminate: false,
      count: 0,
      selectableRowLength,
    });
  }

  function onSelectionChanged(event: RowSelectedEvent) {
    const selectedRows = event.api.getSelectedRows();
    let isChecked, isIndeterminate;
    if (selectedRows.length === 0) {
      isChecked = false;
      isIndeterminate = false;
    } else {
      isChecked =
        !!headerCheckboxState.selectableRowLength &&
        selectedRows.length === headerCheckboxState.selectableRowLength;
      isIndeterminate =
        !!headerCheckboxState.selectableRowLength &&
        selectedRows.length < headerCheckboxState.selectableRowLength;
    }
    setHeaderCheckboxState({
      ...headerCheckboxState,
      isChecked,
      isIndeterminate,
      count: selectedRows.length,
    });
  }

  useImperativeHandle(ref, () => ({
    onRowDataChanged,
    onSelectionChanged,
  }));

  return (
    <div className={className ? className : 'grid-x cell small-12 margin-top-2 px-align-center'}>
      <PrimaryCheckbox
        className={'px-padding-left-2'}
        disabled={!headerCheckboxState.selectableRowLength}
        onChange={onChange}
        data-id="headerCheckbox"
        checked={headerCheckboxState.isChecked}
        indeterminate={headerCheckboxState.isIndeterminate}
        {...checkboxProps}
      />
      {!!headerCheckboxState.count && (
        <span data-id="selectedCount">
          {formatString(itemsSummaryLabel ? itemsSummaryLabel : '{count} Items Selected', {
            count: headerCheckboxState.count,
          })}
        </span>
      )}
      {/* {!!headerCheckboxState.count && */}
      {buttonProps?.map((buttonProp) => (
        <PrimaryButton
          key={buttonProp?.label}
          className="margin-left-1"
          data-id="batchUpdate"
          onClick={buttonProp?.onClick}
          disabled={!headerCheckboxState.count}
          {...buttonProp}>
          {buttonProp?.label}
        </PrimaryButton>
      ))}
    </div>
  );
}
export default React.forwardRef(CustomGridHeaderCheckbox);
