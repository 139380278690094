import { ColDef, GridOptions } from 'ag-grid-community';
import React from 'react';

export interface IUserInfo {
  email: string;
  type: 'BUYER' | 'INTERNAL_USER';
  tenantId: string;
}

export const gridOptions: GridOptions = {
  headerHeight: 32,
  defaultColDef: {
    minWidth: 120,
    wrapText: true,
    autoHeight: true,
    cellStyle: {
      wordBreak: 'normal',
      height: '100%',
    },
    menuTabs: [],
  },
  getRowNodeId: function (data: IUserInfo) {
    return `${data.tenantId}-${data.email}`;
  },
  getRowHeight: () => 54,
  animateRows: false,
  rowSelection: 'multiple',
  suppressAggFuncInHeader: true,
  stopEditingWhenGridLosesFocus: true,
  suppressMovableColumns: true,
  suppressContextMenu: true,
  // grid scroll code
  debounceVerticalScrollbar: true,
  suppressRowClickSelection: true,
  suppressCellSelection: true,
  enableCellTextSelection: true,
  rowBuffer: 5,
  loadingOverlayComponentFramework: () => (
    <div className="px-grid-loader">
      <div className="px-circular-loader"></div>
      <h3 className="text-center margin-top-1">Loading...</h3>
    </div>
  ),
};

export function getColumnDefs(): ColDef[] {
  return [
    {
      checkboxSelection: true,
      cellClass: 'margin-left-1',
      headerClass: 'margin-left-3',
      headerName: 'Tenant ID',
      field: 'tenantId',
    },
    {
      headerName: 'Email',
      field: 'email',
      flex: 2,
    },
    {
      headerName: 'User Type',
      field: 'type',
    },
  ];
}
