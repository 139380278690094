import React, { useEffect, useState } from 'react';
import { Box, MenuItem, Tab } from '@material-ui/core';
import { Prompt, useHistory } from 'react-router-dom';
import {
  BackButton,
  PrimaryButton,
  SecondaryButton,
  TextField,
} from '../../components/AtomComponents';
import { Autocomplete, TabContext, TabList } from '@material-ui/lab';

import Progress from '../../components/Progress/Progress';
import LoadingDialog from '../../components/LoadingDialog/LoadingDialog';

import useDataService from '../../hooks/useDataService';

import { eMessageType } from '../../types/IMessageType';
import { ITenantList } from '../../types/IUpdateConfig';

import { isEmpty } from '../../_lib/utils';
import apiToUrlMap from '../../ApiMapping';

import './CacheManagement.scss';

const EVICT_CACHE = "evictCache";
const RELOAD_CACHE = "reloadCache";

const isEvictCacheTabSelected = (tabState: string) => tabState === EVICT_CACHE;
const isReloadCacheTabSelected = (tabState: string) => tabState === RELOAD_CACHE;

const CacheManagement = () => {
  const [tabState, setTabState] = useState(EVICT_CACHE);
  const [tenantList, setTenantList] = useState<ITenantList>({} as ITenantList);
  const [selectedTenant, setSelectedTenant] = useState('');
  const [selectedCache, setSelectedCache] = useState('');
  const [redisKeys, setRedisKeys] = useState<string[] | null>(null);
  const [redisMasterListKeys, setRedisMasterListKeys] = useState<string[] | null>(null);
  const [cacheKeyPattern, setCacheKeyPattern] = useState('');

  const [isLoading, setIsLoading] = useState(false);
  const { openSnackBar, fetchUrl } = useDataService();

  const history = useHistory();

  const handleChangeTab = (event: any, newValue: any) => {
    setTabState(newValue);
    setSelectedCache('');
    setCacheKeyPattern('');
  };

  const getAllTenant = async () => {
    try {
      const result = await fetchUrl('GET', '/update-config/list-tenants');
      setTenantList(result);
    } catch (e: any) {
      openSnackBar(
        e.message || 'Something went wrong, Unable to fetch tenants',
        eMessageType.error
      );
      history.push('/dashboard');
    }
  };

  const getRedisKeys = async () => {
    try {
      const keys = await fetchUrl('GET', apiToUrlMap.getRedisCache);
      setRedisKeys(keys);
    } catch (e: any) {
      openSnackBar(
        e.message || 'Something went wrong, Unable to fetch redis cache keys',
        eMessageType.error
      );
      history.push('/dashboard');
    }
  };

  const getRedisMasterListKeys = async () => {
    try {
      const keys = await fetchUrl('GET', apiToUrlMap.getRedisMasterListKeys);
      const sortedKeys = keys.sort();
      setRedisMasterListKeys(sortedKeys);
    } catch (e: any) {
      openSnackBar(
        e.message || 'Something went wrong, Unable to fetch redis cache keys',
        eMessageType.error
      );
      history.push('/dashboard');
    }
  };

  useEffect(() => {
    getAllTenant();
    getRedisKeys();
    getRedisMasterListKeys();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTenantChange = (e: React.ChangeEvent<{ value: string }>) => {
    setSelectedTenant(e.target.value);
    setCacheKeyPattern('');
  };

  const validateCacheKeyPattern = () => {
    const regex = /.+\|.+/;
    return cacheKeyPattern.match(regex);
  };

  const evictCacheEntry = async () => {
    try {
      setIsLoading(true);
      if (!validateCacheKeyPattern()) {
        throw new Error('Invalid cacheKeyPattern');
      }
      const res = await fetchUrl('PUT', apiToUrlMap.evictRedisCache, {
        body: { cacheKeyPattern },
      });
      openSnackBar(res.message, eMessageType.success);
      await getRedisKeys();
      setCacheKeyPattern('');
    } catch (e: any) {
      openSnackBar(
        e.message || 'Something went wrong, Unable to Evict Cache Entry',
        eMessageType.error
      );
    } finally {
      setIsLoading(false);
    }
  };

  const reloadCache = async () => {
    try {
      setIsLoading(true);
      const res = await fetchUrl('PUT', apiToUrlMap.reloadRedisCache, {
        body: {
          tenantId: selectedTenant,
          cacheName: selectedCache,
        },
      });
      openSnackBar(res.message, eMessageType.success);
      setSelectedCache('');
    } catch (e: any) {
      openSnackBar(e.message || 'Something went wrong, Unable to Reload Cache', eMessageType.error);
    } finally {
      setIsLoading(false);
    }
  };

  const cancelChanges = () => {
    setSelectedTenant('');
    setCacheKeyPattern('');
    setSelectedCache('');
  };

  const filterOptions = (options: string[]) => {
    if(isReloadCacheTabSelected(tabState)) {
      const sanitizedCache = selectedCache.replace('*', '');
      return options.filter(option => option.startsWith(sanitizedCache));
    }

    const newOptions: string[] = [];
    options.forEach((option) => {
      if (
        option.startsWith(selectedTenant) &&
        option.startsWith(cacheKeyPattern.replace('*', ''))
      ) {
        newOptions.push(option);
      }
    });
    return newOptions;
  };

  const handleCacheChange = (newInputValue: string) => {
    if (isEvictCacheTabSelected(tabState)) {
      setCacheKeyPattern(newInputValue);
      return;
    }
    setSelectedCache(newInputValue);
  };

  if (isEmpty(tenantList) || !redisKeys || !redisMasterListKeys) {
    return (
      <>
        <h3>Loading...</h3>
        <Progress />
      </>
    );
  }

  return (
    <div className="px-update-config">
      <div className="cell small-6 medium-6 px-one-line-data">
        <LoadingDialog isDialogOpen={isLoading} />
        <BackButton />
        <h3 className="small-12">Cache Management</h3>
      </div>
      <div className="grid-x ">
        <Prompt
          when={!!cacheKeyPattern || !!selectedCache}
          message={'You have some unsaved changes. OK to forget these changes and leave the page?'}
        />
        <div className="cell auto margin-bottom-1 action-button">
          <SecondaryButton className="margin-top-1" onClick={cancelChanges}>
            CANCEL CHANGES
          </SecondaryButton>
          {isEvictCacheTabSelected(tabState) ? (
            <PrimaryButton
              className="margin-left-1 margin-top-1"
              disabled={!cacheKeyPattern}
              onClick={evictCacheEntry}>
              EVICT CACHE ENTRY
            </PrimaryButton>
          ) : (
            <PrimaryButton
              className="margin-left-1 margin-top-1"
              disabled={!selectedCache}
              onClick={reloadCache}>
              RELOAD CACHE
            </PrimaryButton>
          )}
        </div>
      </div>
      <div className="small-12 margin-top-3">
        <Box>
          <TabContext value={tabState}>
            <Box>
              <TabList onChange={handleChangeTab} aria-label="Cache reload evict">
                <Tab label="Evict Cache" value={EVICT_CACHE} />
                <Tab label="Reload Cache" value={RELOAD_CACHE} />
              </TabList>
            </Box>
            <div className="margin-top-2 margin-bottom-2 cell small-6 tenant-select">
              <TextField
                select
                value={selectedTenant}
                onChange={handleTenantChange}
                label="Tenant Name"
                className="text-field"
                fullWidth
                disabled={Object.keys(tenantList.tenants)?.length <= 1}>
                {tenantList.tenants?.map((tenant: string) => (
                  <MenuItem key={tenant} value={tenant}>
                    {tenant}
                  </MenuItem>
                ))}
              </TextField>
            </div>
            <div className="margin-top-2 margin-bottom-2 cell small-6 tenant-select">
              <Autocomplete
                value={isEvictCacheTabSelected(tabState) ? cacheKeyPattern : selectedCache}
                options={isEvictCacheTabSelected(tabState) ? redisKeys : redisMasterListKeys}
                clearOnBlur={false}
                filterOptions={filterOptions}
                disabled={!selectedTenant}
                fullWidth
                color="primary"
                getOptionLabel={(option: string) => option}
                renderInput={(params) => (
                  <TextField {...params} label={'Redis Keys'} variant="outlined" />
                )}
                onInputChange={(event, newInputValue) => handleCacheChange(newInputValue)}
              />
            </div>
          </TabContext>
        </Box>
      </div>
    </div>
  );
};

export default CacheManagement;
