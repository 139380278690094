import React, { useEffect, useState } from 'react';
import LoadingDialog from '../../components/LoadingDialog/LoadingDialog';
import { BackButton, PrimaryButton, PrimaryCheckbox, TextField } from '../../components/AtomComponents';
import { MenuItem } from '@material-ui/core';
import { ITenantList } from '../../types/IUpdateConfig';
import useDataService from '../../hooks/useDataService';
import { eMessageType } from '../../types/IMessageType';
import './SplashConfig.scss';
import apiToUrlMap from '../../ApiMapping';

type CheckedStatusType = {
    tenantAdminSite: boolean;
    buyerSite: boolean;
};

const SplashConfig = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [selectedTenant, setSelectedTenant] = useState('');
    const [tenantList, setTenantList] = useState<ITenantList>({} as ITenantList);
    const [checkedValues, setCheckedValues] = useState<CheckedStatusType>({ tenantAdminSite: false, buyerSite: false });

    const { openSnackBar, fetchUrl } = useDataService();

    useEffect(() => {
        getAllTenant();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const tenantChanged = (e: React.ChangeEvent<{ value: string }>) => {
        setSelectedTenant(e.target.value);
    };

    const getAllTenant = async () => {
        try {
            setIsLoading(true);
            const result = await fetchUrl('GET', '/update-config/list-tenants');
            setTenantList(result);
        } catch (e: any) {
            openSnackBar(e.message || 'Something went wrong, Unable to fetch tenant', eMessageType.error);
        } finally {
            setIsLoading(false);
        }
    };

    const splashPageHandler = async (operation: 'add' | 'remove') => {
        try {
            if (!selectedTenant) {
                openSnackBar('Please select a tenant.', eMessageType.error);
                return;
            }
            setIsLoading(true);

            const payload = {
                tenant_id: selectedTenant,
                tenant_page: checkedValues.tenantAdminSite,
                buyer_page: checkedValues.buyerSite,
                operation,
            };

            const { Status } = await fetchUrl('POST', apiToUrlMap.splashConfig, { body: payload });
            if (Status === 'Successful') {
                openSnackBar(`Splash page ${operation}ed successfully.`, eMessageType.success);
            } else {
                openSnackBar(`Failed to ${operation} splash page.`, eMessageType.error);
            }
        } catch (error) {
            openSnackBar('An error occurred. Please try again.', eMessageType.error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleCheckBoxSelection = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, checked } = event.target;
        setCheckedValues((prevValues) => ({
            ...prevValues,
            [name]: checked,
        }));
    };

    return (
        <div className="px-splash-config">
            <div className="cell small-6 medium-6 px-one-line-data">
                <LoadingDialog isDialogOpen={isLoading} />
                <BackButton />
                <h3 className="small-12">Splash Page Config</h3>
            </div>
            <div className="margin-top-2 margin-bottom-2 cell small-6 tenant-select">
                <TextField
                    select
                    value={selectedTenant}
                    onChange={tenantChanged}
                    label="Tenant Name"
                    className="text-field"
                    disabled={tenantList?.tenants && Object.keys(tenantList?.tenants).length <= 1}
                >
                    {tenantList?.tenants &&
                        tenantList?.tenants.map((tenant: string) => (
                            <MenuItem key={tenant} value={tenant}>
                                {tenant}
                            </MenuItem>
                        ))}
                </TextField>
            </div>
            <p>
                <PrimaryCheckbox
                    name="tenantAdminSite"
                    disabled={!selectedTenant}
                    checked={checkedValues.tenantAdminSite}
                    onChange={handleCheckBoxSelection}
                />
                Tenant Admin Site
            </p>
            <p>
                <PrimaryCheckbox
                    name="buyerSite"
                    disabled={!selectedTenant}
                    checked={checkedValues.buyerSite}
                    onChange={handleCheckBoxSelection}
                />
                Buyer Site
            </p>

            <div className="grid-x">
                <div className="margin-right-1 cell shrink">
                    <PrimaryButton onClick={() => splashPageHandler('add')} disabled={!checkedValues.buyerSite && !checkedValues.tenantAdminSite}>ADD SPLASH PAGE</PrimaryButton>
                </div>
                <div className="margin-right-1 cell shrink">
                    <PrimaryButton onClick={() => splashPageHandler('remove')} disabled={!checkedValues.buyerSite && !checkedValues.tenantAdminSite}>REMOVE SPLASH PAGE</PrimaryButton>
                </div>
            </div>
        </div>
    );
};

export default SplashConfig;
