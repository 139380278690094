import React, { useImperativeHandle, useState } from 'react';
import { PrimaryButton, TextField } from '../AtomComponents';
import { InputAdornment, Tooltip } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { Close, HelpOutline } from '@material-ui/icons';

interface ISearchOnButtonClick {
  onSearch: (searchTerm: string) => Promise<void>;
  tooltipHeading?: string;
  placeholder?: string;
  className?: string;
  showTooltip?: boolean;
}

export default React.forwardRef(function SearchOnButtonClick(
  { onSearch, tooltipHeading, placeholder, className, showTooltip = false }: ISearchOnButtonClick,
  ref: any
) {
  const [searchTerm, setSearchTerm] = useState<string>('');

  function onSearchChange(e: React.ChangeEvent<HTMLInputElement>) {
    setSearchTerm(e.target.value);
  }

  useImperativeHandle(ref, () => ({
    getEmailValue: () => searchTerm,
  }));

  async function handleSearch(e: React.MouseEvent<HTMLButtonElement>) {
    e.preventDefault();
    const trimedSearchTerm = searchTerm?.trim();
    await onSearch(trimedSearchTerm);
  }

  async function clearSearchField() {
    setSearchTerm('');
    await onSearch('');
  }

  const tip = (
    <div className="px-tooltip px-text-description">
      <div className="px-tooltip-heading">
        <HelpOutline /> Tips on Using Search
      </div>
      <div className="px-tooltip-description">
        <span className="px-tooltip-font-large">
          {tooltipHeading ? tooltipHeading : 'Match on list of SKUs from a spreadsheet'}
        </span>
        <span>Select a range of cells in a spreadsheet,</span>
        <span>then copy and paste into the search input</span>
      </div>
    </div>
  );

  return (
    <div className={`${className} grid-x px-align-center`}>
      <TextField
        size="medium"
        name="search-on-button-click-textfield"
        className="cell small-9"
        placeholder={placeholder || ''}
        aria-describedby="Search item"
        value={searchTerm}
        onChange={onSearchChange}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              {searchTerm ? <Close onClick={clearSearchField} className="pointer-mouse" /> : <></>}
            </InputAdornment>
          ),
        }}
      />
      {showTooltip && (
        <div className="cell small-1 px-search-q margin-left-1">
          <Tooltip
            id="search-tooltip"
            title={tip}
            defaultChecked={true}
            TransitionProps={{ timeout: 0 }}
            interactive>
            <div>
              <HelpOutline />
            </div>
          </Tooltip>
        </div>
      )}
      <PrimaryButton
        data-id="search-on-button-click-button"
        className="margin-left-1"
        type="submit"
        onClick={handleSearch}>
        Search
      </PrimaryButton>
    </div>
  );
});
